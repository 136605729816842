<template>
  <article>
    <div class="hero">
      <h1>Compliance</h1>
      <p class="subh">
        Governança Corporativa, Compliance e Ética Empresarial. Menos teoria, mais prática. Conheça nossas ações.
      </p>
    </div>
    <section class="secao-compliance container-base">
      <h1 style="text-align: center">
        Apresentação
      </h1>
      <p>
        A AHBB|Rede Santa Casa está em constante evolução institucional para implementação do sistema de Boa-Governança Corporativa, Compliance e Ética Empresarial. O objetivo principal desta adequação diz respeitos às diretrizes essenciais ao desenvolvimento empresarial e econômico da sociedade brasileira, buscando trazer lisura e firmando o compromisso ético perante de todos os membros, diretores, conselheiros, voluntários e colaboradores da AHBB|Rede Santa Casa, com a sociedade e com o Poder Público. A AHBB está criando um código de conduta ética a ser seguido por todos os seus componentes, primando pelo ambiente corporativo de desenvolvimento racional e sustentável em todos os campos de atuação da instituição.
      </p>
      <h1 style="text-align: center">
        Ações
      </h1>
      <ul class="acoes-compliance">
        <li v-for="(acao, i) in acoes" :key="i" v-html="acao.texto" />
      </ul>
      <h1 v-if="documentos.length" style="text-align: center">
        Documentos
      </h1>
      <div v-if="documentos.length" class="documentos-compliance">
        <a
          v-for="(doc, i) in documentos"
          :key="i" class="documento"
          :href="doc.arquivo? doc.arquivo.data.full_url : null"
          target="_blank"
          :style="{'animation-delay': .1 + .03*i + 's'}"
          :class="{loading}"
        >
          <img src="@/assets/ic_download_arquivo.svg" class="icone-download">
          <transition name="crossfade" mode="out-in">
            <span v-if="!loading" key="titulo">{{ doc.titulo }}</span>
            <span v-else class="skeleton" key="skeleton">Carregando{{ Array(Math.floor(Math.random() * 16)).fill('.').join('') }}</span>
          </transition>
        </a>
      </div>
    </section>
  </article>
</template>

<style lang="scss" scoped>
  .secao-compliance {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .hero {
    background-image: url('../assets/bg_compliance.jpg');

    @media
    screen and (min-device-pixel-ratio: 2) and (min-width: 1920px),
    screen and (min-resolution: 2dppx) and (min-width: 1920px) {
      background-image: url('../assets/bg_compliance@2x.jpg');
    }
  }

  .acoes-compliance {
    padding-left: 12px;

    li {
      margin-top: 8px;
      margin-bottom: 16px;
      font-size: large;
    }
  }

  .documentos-compliance {
    display: grid;
    flex-direction: row;
    flex-wrap: wrap;

    grid-template-columns: 1fr 1fr;
    gap: 0 20px;

    @media screen and (max-width: 680px) {
      grid-template-columns: 1fr;
    }

    .documento {
      display: flex;
      flex: 1 0 320px;

      flex-direction: row;
      align-items: center;

      border-bottom: 1px solid gray;

      transition: background-color .2s;

      text-decoration: none;
      color: var(--ahbb-blue);

      animation: fade-down .1s ease-out both;

      &:not(.loading) {
        &:hover {
          background-color: var(--ahbb-green-light-3);
          cursor: pointer;
        }

        &:active {
          background-color: var(--ahbb-green-light-2);
        }
      }
      
      .icone-download {
        width: 24px;
        height: 24px;
        margin: 8px;
      }

      span.skeleton {
        color: transparent;
        background: #31425F23;
      }

      @keyframes fade-down {
        0% {
          transform: translateY(-10px);
          opacity: 0;
        }
      }
    }
  }

  .crossfade-enter-active, .crossfade-leave-active {
    animation: none !important;
    transition: opacity .15s ease-in-out !important;
  }

  .crossfade-enter, .crossfade-leave-to {
    opacity: 0 !important;
  }

</style>

<script>
import cms from '@/lib/cms.js'
export default {
  name: 'Compliance',
  data: () => ({
    documentos: [],
    acoes: Array(4).fill(''),
    loading: true,
  }),
  async created() {
    this.loading = true
    this.documentos = (await cms.getItems('compliance_documentos', { fields: '*,arquivo.data' })).data
    this.acoes = (await cms.getItems('compliance_acoes', { fields: '*' })).data
    this.loading = false
  },
  metaInfo: {
    title: 'Compliance',
  },
}
</script>
